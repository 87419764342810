<template>
  <div>
    <div class="contain1">
      <div style="font-size: 14px">
        区域名称
        <el-input
            size="mini"
            type="text"
            v-model="search.addrName"
            placeholder="输入区域名称"
            class="m1150 input340"
        ></el-input>
             安检员
        <el-select
            clearable
            filterable
            size="mini"
            style="margin-left:23px;"
            v-model="search.sysUserId"
            class="m1150"
            placeholder="安检员"
            @change="inquire"
        >
          <el-option
              v-for="item in AllInspectors"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button
            size="mini"
            type="primary"
            style="margin-left: 20px"
            icon="el-icon-search"
            @click="inquire"
        >查询</el-button>
        <el-button
            size="mini"
            class="ml20"
            icon="el-icon-refresh"
            @click="refresh"
        >重置</el-button>
      </div>
    </div>

    <div class="contain2">
      <div class="btn-top" style="width: 10%">
        <el-button size="mini" type="warning" icon="" @click="delItems"
        >批量分配安检员</el-button
        >
        <el-button size="mini" type="primary" icon="" @click="add" class="ml20"
        >添加</el-button
        >
      </div>
      <el-table
          ref="multipleTable"
          :data="tableData"
          border
          :header-cell-style="{ background: '#f5f7fa' }"
          tooltip-effect="dark"
          style="width: 100%"
          height="620"
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
            type="index"
            :index="indexMethod"
            width="50"
            label="序号"
        ></el-table-column>
        <!-- <el-table-column prop="id" label="区域id" align="center" width="100"></el-table-column> -->
        <el-table-column
            prop="addrName"
            label="区域名称"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="realname"
            label="安检员"
            align="center"
        ></el-table-column>
        <el-table-column fixed="right" label="操作" width="120" align="left">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="edit(scope.row)"
            >编辑</el-button
            >
            <el-button
                type="text"
                size="small"
                style="color: red"
                @click="delRow(scope.row)"
            >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          style="text-align: center; margin-top: 15px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="search.current"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="search.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
      <!-- 弹出层 -->
      <el-dialog
          :title="saveStatus == 1 ? '添加行政区域' : '编辑行政区域'"
          :close-on-click-modal="false"
          :visible.sync="dialogFormVisible"
          width="40%"
          @close="dialogClose"
      >
        <el-form :model="form" ref="form" :rules="rules">
          <!-- <el-form-item label="区域编码" prop="addrcode" :label-width="formLabelWidth">
              <el-input type="number" v-model="form.addrcode" autocomplete="off" style="width:80%"></el-input>
          </el-form-item> -->
          <el-form-item
              label="区域名称"
              prop="addrName"
              :label-width="formLabelWidth"
          >
            <el-input
                v-model="form.addrName"
                autocomplete="off"
                placeholder="请输入区域名称"
                style="width: 80%"
            ></el-input>
          </el-form-item>

          <el-form-item
              label="安检员"
              prop="sysUserId"
              :label-width="formLabelWidth"
          >
            <el-select v-model="form.sysUserId">
              <el-option
                  v-for="item in AllInspectors"
                  :key="item.id"
                  :label="item.realname"
                  :value="String(item.id)"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="updateForm">确 定</el-button>
        </div>
        <!-- 地图 -->
      </el-dialog>





          <!-- 弹出层 -->
      <el-dialog
          title="批量分配安检员"
          :close-on-click-modal="false"
          :visible.sync="dialogBatchVisible"
          width="40%"
          @closed="closeBatch"
      >
        <el-form :model="form1"  ref="form1" :rules="rule1">

          <el-form-item
              label="安检员"
              prop="selectedSysUserId"
              :label-width="formLabelWidth"
          >
            <el-select v-model="form1.selectedSysUserId">
              <el-option
                  v-for="item in AllInspectors"
                  :key="item.id"
                  :label="item.realname"
                  :value="String(item.id)"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogBatchVisible = false">取 消</el-button>
          <el-button type="primary" @click="batch">确 定</el-button>
        </div>
        <!-- 地图 -->
      </el-dialog>



    </div>
  </div>
</template>
<script>
import {
  selectAddr,selectInspectors,savegsCheckAddr,changeCheckAddr,batchDeleteAddr,batchSetInspector
} from "../../RequestPort/business/administrative";
export default {
  data() {
    return {
      options: [],
      tableData: [],
      selectedAddr:[],
      search: {
        addrName: "", // 区域名称
        sysUserId: "",
        current: 1,
        size: 100,
      },
      findObj: {},
      total: 0,
      form: {
        // addrcode: "", // 区域编码
        addrName: "", // 区域名称
        sysUserId:'',
      },
      rules: {
        // addrcode:[{ required: true, message: '请输入区域编码', trigger: 'blur' }],
        addrName: [
          { required: true, message: "请输入区域名称", trigger: "blur" },
        ],
        sysUserId: [
          { required: true, message: "请选择安检员", trigger: "change" },
        ],
      },

      rule1: {
 
        selectedSysUserId: [
          { required: true, message: "请选择安检员", trigger: "change" },
        ],
      },
      form1:{
        selectedSysUserId:'',
      },
      dialogFormVisible: false,
      dialogBatchVisible: false,
      innerVisible: false,
      saveStatus: 1,
      chosedItems: [],
      formLabelWidth: "120px",
      chosedGeo: {},
      AllInspectors:[],
    };
  },
  mounted() {
    this.getList()
    selectInspectors().then((e)=>{
      this.AllInspectors = e.data
    })
  },
  methods: {
    closeBatch(){
       this.$refs.form1.resetFields();
    },
    batch(){
        this.$refs.form1.validate((valid) => {
        if (valid) {
          batchSetInspector({"addrIds": this.selectedAddr, "id": this.form1.selectedSysUserId}).then((e)=>{
              this.$message.success(e.msg);
              this.dialogBatchVisible = false;
              this.getList();
          });
        }
        })
      
    },

    indexMethod(index) {
      return (this.search.current - 1) * this.search.size + index + 1;
    },
    objCopy () {
      for (let key in this.search){
        this.findObj[key] = this.search[key];
      }
    },
    refresh () {
      this.search = {
        addrName: "", // 区域名称
        city: "",
        district: "",
        province: "",
        current: 1,
        size: 100,
        sysUserId:''
      }
      this.objCopy()
      this.getList();
    },
    searchCityChange(e) {
      this.search.province = e[0] ? e[0] : "";
      this.search.city = e[1] ? e[1] : "";
      this.search.district = e[2] ? e[2] : "";
      this.objCopy()
      this.getList();
      this.$refs.cascaderSearch.dropDownVisible = false; //监听值发生变化就关闭它
    },
    clickNode($event) {
      $event.target.parentElement.parentElement.firstElementChild.click();
    },
    edit(row) {
      this.form.cascaderList = [];
      this.saveStatus = 2;
      this.dialogFormVisible = true;
      this.form.cascaderList.push(row.province);
      this.form.cascaderList.push(row.city);
      this.form.cascaderList.push(row.district);
      this.$nextTick(() => {
        let keys = Object.keys(row);
        keys.forEach((item) => {
          this.form[item] = row[item];
        });
      });
    },
    delRow(row) {
      this.$confirm(`是否删除区域[ ${row.addrName} ]?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        batchDeleteAddr([row.id]).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    delItems() {
      if (this.chosedItems.length === 0) {
        this.$message.error("请先勾选要分配的区域");
        return false;
      }
      this.chosedItems.forEach((item) => {
        this.selectedAddr.push(item.id);
      });
      this.dialogBatchVisible = true;
  
    },
    confirmGeo() {
      if (!this.chosedGeo.address) {
        this.$message.error("无效地址");
        return;
      }
      if (Object.keys(this.chosedGeo).length > 0) {
        this.innerVisible = false;
        this.form.lng = this.chosedGeo.position.lng;
        this.form.lat = this.chosedGeo.position.lat;
        this.form.town = this.chosedGeo.address;
      } else {
        this.$message.error("请先选择区域");
      }
    },
    cancelGeo() {
      this.innerVisible = false;
    },
    chooseGeo() {
      let city = this.$refs.cascader.getCheckedNodes();
      if (city.length === 0) {
        this.$message.error("请先选择城市/区域");
        return false;
      }
      this.innerVisible = true;
      if (!this.map) {
        this.$nextTick(() => {
          let _this = this;
          AMapUI.loadUI(["misc/PositionPicker"], function (PositionPicker) {
            //地图加载
            var map = new AMap.Map("container", {
              resizeEnable: true,
              scrollWheel: false,
              doubleClickZoom: false,
            });
            AMap.plugin(
                [
                  "AMap.ToolBar",
                  "AMap.Scale",
                  "AMap.HawkEye",
                  "AMap.MapType",
                  "AMap.Geolocation",
                ],
                function () {
                  // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
                  map.addControl(
                      new AMap.ToolBar({ position: { top: "15px", left: "15px" } })
                  );
                  // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
                  map.addControl(
                      new AMap.Scale({ position: { bottom: "15px", left: "15px" } })
                  );
                  // // 在图面添加鹰眼控件，在地图右下角显示地图的缩略图
                  map.addControl(
                      new AMap.HawkEye({
                        isOpen: true,
                        position: { bottom: "0px", right: "0px" },
                      })
                  );
                }
            );
            // map.addControl(new AMap.ToolBar({
            //    liteStyle: true
            // }))
            // 地理编码
            if (_this.form.town.length > 0) {
              AMap.plugin("AMap.Geocoder", function () {
                map.setZoomAndCenter(12, [_this.form.lng, _this.form.lat]);
              });
            } else {
              AMap.plugin("AMap.Geocoder", function () {
                var geocoder = new AMap.Geocoder({
                  city: _this.$refs.cascader.getCheckedNodes()[0].parent.label,
                });
                geocoder.getLocation(
                    _this.$refs.cascader.getCheckedNodes()[0].data.name,
                    function (status, result) {
                      if (status === "complete" && result.info === "OK") {
                        map.setZoomAndCenter(12, [
                          result.geocodes[0].location.lng,
                          result.geocodes[0].location.lat,
                        ]);
                      } else {
                        this.$message.error("未找到该区域");
                      }
                    }
                );
              });
            }
            //输入提示
            var autoOptions = {
              city: city[0].parent.label,
              input: "tipinput",
            };
            AMap.plugin(["AMap.AutoComplete", "AMap.PlaceSearch"], function () {
              // var autoOptions = {
              //   city: city[0].parent.label,
              //   input: "tipinput"
              // };
              var autocomplete = new AMap.AutoComplete(autoOptions);
              var placeSearch = new AMap.PlaceSearch({
                city: city[0].parent.label,
                map: map,
              });
              autocomplete.on("select", function (e) {
                _this.chosedGeo = e;
                // placeSearch.search(e.poi.name)
                map.setCenter([e.poi.location.lng, e.poi.location.lat]);
                // map.setCenter()
              });
            });
            // var auto = new AMap.Autocomplete(autoOptions);
            // var placeSearch = new AMap.PlaceSearch({
            //     map: map
            // });  //构造地点查询类
            // AMap.event.addListener(auto, "select", function (e) {
            //     map.setZoomAndCenter(14, [e.poi.location.lng, e.poi.location.lat]);
            //     // _this.chosedGeo = e
            //     // placeSearch.setCity(e.poi.adcode);
            //     // placeSearch.search(e.poi.name);  //关键字查询查询
            // });//注册监听，当选中某条记录时会触发
            /**拖拽选址 */
            var positionPicker = new PositionPicker({
              mode: "dragMap",
              map: map,
            });
            positionPicker.on("success", function (positionResult) {
              _this.chosedGeo = positionResult;
            });
            positionPicker.on("fail", function (positionResult) {});
            positionPicker.start();
            map.on("click", (e) => {
              map.setCenter([e.lnglat.lng, e.lnglat.lat]);
            });
          });
        });
      }
    },
    updateForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if(this.saveStatus == 2){
            changeCheckAddr(this.form).then((e)=>{
              this.getList();
              this.dialogFormVisible = false
              this.$message.success('修改成功')
            })
          }else{
            savegsCheckAddr(this.form).then((e)=>{
              this.getList();
              this.dialogFormVisible = false
              this.$message.success('添加成功')
            })
          }
        }
      });
    },
    add() {
      this.dialogFormVisible = true;
    },
    cityChange(e) {
      // console.log(this.$refs.cascader.getCheckedNodes());
    },
    getCityList() {
      // 城市树
      if (localStorage.getItem("cityTree")) {
        this.options = JSON.parse(localStorage.getItem("cityTree"));
      } else {
        sysCityTree().then((res) => {
          if (res.code === 200) {
            this.options = res.data[0].children;
            localStorage.setItem("cityTree", JSON.stringify(this.options));
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.objCopy()
      this.getList();
    },
    handleSizeChange(val) {
      this.search.size = val;
      this.objCopy()
      this.getList();
    },
    inquire() {
      this.search.current = 1;
      let seachCity = this.search.addrName
      if (seachCity.length === 0) {
        this.search.province = "";
        this.search.city = "";
        this.search.district = "";
      }
      this.objCopy()
      this.getList();
    },
    getList() {
      selectAddr(this.findObj).then((res) => {
        if (res.code === 200) {
          const { records, total } = res.data;
          this.tableData = records;
          this.total = total;
          if (records.length === 0 && res.data.pages >= 1) {
            this.search.current -= 1;
            this.getList();
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    dialogClose() {
      this.saveStatus = 1;
      this.$refs.form.resetFields();
      this.cascaderList = [];
      if (this.form.id) delete this.form.id;
      if (this.form.deleteflag) delete this.form.deleteflag;
      for (let key in this.chosedGeo) {
        delete this.chosedGeo[key];
      }
    },
    handleSelectionChange(e) {
      this.chosedItems = e;
    },
  },
};
</script>

<style lang="less">
.innerDialog {
  margin-top: 17vh !important;
  height: 70%;
  .el-dialog__body {
    height: 70%;
  }
}
#container {
  width: 100%;
  height: 100%;
}
#myPageTop {
  position: absolute;
  top: 90px;
  right: 30px;
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ccc;
  margin: 10px auto;
  padding: 6px;
  font-family: "Microsoft Yahei", "微软雅黑", "Pinghei";
  font-size: 14px;
}
#myPageTop label {
  margin: 0 20px 0 0;
  color: #666666;
  font-weight: normal;
}
#myPageTop input {
  width: 170px;
}
#myPageTop .column2 {
  padding-left: 25px;
}
.amap-sug-result {
  z-index: 10001 !important;
}
</style>