import axios from '@/common/js/request'

export function savegsCheckAddr(data){
    const savegsCheckAddr = axios({
        url: '/gsCheckAddr/save ',
        method: 'post',
        data
    })
    return savegsCheckAddr
}

export function selectAddr(params){
    const selectAddr = axios({
        url: '/gsCheckAddr/selectAddr',
        method: 'get',
        params
    })
    return selectAddr
}

export function selectInspectors(params){
    const selectInspectors = axios({
        url: '/gsCheckAddr/selectInspectors',
        method: 'get',
        params
    })
    return selectInspectors
}

///gsCheckAddr/update

export function changeCheckAddr(data){
    const changeCheckAddr = axios({
        url: '/gsCheckAddr/update',
        method: 'post',
        data
    })
    return changeCheckAddr
}

//gsCheckAddr/batchDeleteAddr
export function batchDeleteAddr(data){
    const batchDeleteAddr = axios({
        url: '/gsCheckAddr/batchDeleteAddr ',
        method: 'post',
        data
    })
    return batchDeleteAddr
}


export function batchSetInspector(data){
    const batchSetInspector = axios({
        url: '/gsCheckAddr/batchSetInspector ',
        method: 'post',
        data
    })
    return batchSetInspector
}